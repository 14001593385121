:root{
    --main-action-color: rgb(118, 209, 166);
}

body{
    font-family: "Verdana", sans-serif;
    line-height: 1.5;
    color: #333333;
    background-color: #F3F3F9;
    margin: 0 auto;
}

body.dark{
    color: white;
    background-color: rgb(34, 34, 46);
}

a{
    color: rgb(146, 118, 209);
}

.article-descr{
    color: #777777;
    padding-left: 10px;
    font-size: 14px;
}

header{
    margin: 0 auto;
    background-color: white;
    padding: 15px 12%;
    border-bottom: 1px solid rgba(74, 94, 102, 0.2);
}

header .logo{
    font-family: monospace;
    font-size: 24px;
    font-weight: bold;
    color: rgb(146, 118, 208);
}

header .logo a{
    text-decoration: none;
}


@media(min-width: 900px){
    header .site-nav{
        float: right;
        line-height: 42px;
    }
}

@media(max-width: 899px){
    header .site-nav{
        display: none;
    }
}

header .site-nav a{
    margin: 30px;
    color: #333333;
    text-decoration: none;
}

.main-bar{
    margin: 0 auto;
    max-width: 720px;
    display: flex;
    justify-content: space-evenly;
    font-size: 18px;
}

.main-content{
    width: 65%;
}

.side-content{
    width: 25%;
}

#homepage-bar{
    background: linear-gradient(25deg, rgb(173, 46, 208), rgba(94, 84, 166,1));
    color:white;
    padding: 40px;
    margin: 0 auto;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

#homepage-bar h1{
    padding-top: 0px;
    margin-top: 0px;
}


#homepage-bar .column{
    width: 40%;
}

#homepage-bar .column .highlight{
    color: #333333;
    width: 50%;
    margin: 0 auto;
    text-align: left;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}

#homepage-bar .column .btn-wrapper{
    background-color: white;
    margin: 0 auto;
    width: 50%;
    border: 1px solid white;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    padding: 15px 0;
}

#homepage-bar .column .btn-wrapper input{
    border-radius: 3px;
    padding: 10px 15px;
    background-color: white;
    font-weight: bold;
    color: var(--main-action-color);
    border: 3px solid var(--main-action-color);
    font-size: 16px;
}

#homepage-bar .column .btn-wrapper input:hover{
    color: white;
    cursor: pointer;
    background-color: var(--main-action-color);
}

#homepage-bar .column code{
    color: #333333;
}

.centered{
    padding: 0px 30%;
}
ul{
    list-style-type: none;
    margin: 0;
    padding: 0 10px;
}

#sign-up-form input{
    font-size: 18px;
}


#sign-up-form input[type=email]{
    padding: 10px;
    border: 1px solid var(--main-action-color);
}
#sign-up-form input:focus{
    -webkit-tap-highlight-color: var(--main-action-color); 
    -webkit-focus-ring-color: var(--main-action-color); 
    outline: var(--main-action-color) !important;
}


#sign-up-form button{
    font-size: 18px;
    padding: 10px;
    color: white;
    background-color: var(--main-action-color);
    border: 1px solid var(--main-action-color);
}
#sign-up-form button:hover{
    cursor:pointer;
}

/*
.container{
display: flex;
flex-flow: row wrap;
align-items: flex-start;
justify-content: center;
width: 100%;
}*/
    @media(max-width: 899px){
        .container{
            column-count: 1;
            padding: 0px 20px;

        }
    }
    @media(min-width: 900px){
        .container{
            column-count:2;
            column-gap: 10px;
            padding: 0px 30px;
        }
    }
    @media(min-width:1400px){
        .container{
            column-count:3;
            column-gap: 30px;
            padding: 0 7%;
        }
    }

    .about{
        text-align: center;
    }


    .section{
        display: grid;
        grid-template-rows: 1fr auto;
        margin-bottom: 10px;
        break-inside: avoid;
    }

    h1{
        font-size: 36px;
    }

    h2{
        font-weight: 100;
        font-size: 28px;
    }

    h3{
        font-weight: 100;
        font-size: 22px;
        margin-bottom: 8px;
    }

    h4{
        font-weight: 100;
        margin: 8px 0px 0px 0px;
        color: rgb(146, 118, 208);

    }

    footer{
	padding: 40px 0px;
    }

    footer hr{
	color: rgba(74,94,102,.2);
    }

    footer p{
	text-align: center;
    }
    
    pre {
	padding-bottom: 5px;
	margin: 5px 0;
	line-height: 125%;
	overflow: auto;
	text-size-adjust:none;
	-webkit-text-size-adjust:none;
	-moz-text-size-adjust:none;
	-ms-text-size-adjust:none;
    }
    td.linenos .normal { color: inherit; background-color: transparent; padding-left: 5px; padding-right: 5px; }
    span.linenos { color: inherit; background-color: transparent; padding-left: 5px; padding-right: 5px; }
    td.linenos .special { color: #000000; background-color: #ffffc0; padding-left: 5px; padding-right: 5px; }
    span.linenos.special { color: #000000; background-color: #ffffc0; padding-left: 5px; padding-right: 5px; }
    .highlight .hll { background-color: #ffffcc }
    .highlight { 
	background: #ffffff; 
	box-shadow: rgba(74, 94, 102, 0.03) 0px 8px 20px, rgba(74, 94, 102, 0.4) 0px 1px 1px;
	border: 1px solid rgba(74, 94, 102, 0.2);
	overflow: auto;
	padding: 5px 10px 0px 10px;
    }
    .highlight .c { color: #666666; font-style: italic } /* Comment */
    .highlight .err { color: #FF0000; background-color: #FFAAAA } /* Error */
    .highlight .k { color: #228899; font-weight: bold } /* Keyword */
    .highlight .o { color: #333333 } /* Operator */
    .highlight .ch { color: #666666; font-style: italic } /* Comment.Hashbang */
    .highlight .cm { color: #666666; font-style: italic } /* Comment.Multiline */
    .highlight .cp { color: #557799 } /* Comment.Preproc */
    .highlight .cpf { color: #666666; font-style: italic } /* Comment.PreprocFile */
    .highlight .c1 { color: #666666; font-style: italic } /* Comment.Single */
    .highlight .cs { color: #cc0000; font-weight: bold; font-style: italic } /* Comment.Special */
    .highlight .gd { color: #A00000 } /* Generic.Deleted */
    .highlight .ge { font-style: italic } /* Generic.Emph */
    .highlight .gr { color: #FF0000 } /* Generic.Error */
    .highlight .gh { color: #000080; font-weight: bold } /* Generic.Heading */
    .highlight .gi { color: #00A000 } /* Generic.Inserted */
    .highlight .go { color: #888888 } /* Generic.Output */
    .highlight .gp { color: #c65d09; font-weight: bold } /* Generic.Prompt */
    .highlight .gs { font-weight: bold } /* Generic.Strong */
    .highlight .gu { color: #800080; font-weight: bold } /* Generic.Subheading */
    .highlight .gt { color: #0044DD } /* Generic.Traceback */
    .highlight .kc { color: #228899; font-weight: bold } /* Keyword.Constant */
    .highlight .kd { color: #228899; font-weight: bold } /* Keyword.Declaration */
    .highlight .kn { color: #228899; font-weight: bold } /* Keyword.Namespace */
    .highlight .kp { color: #0088ff; font-weight: bold } /* Keyword.Pseudo */
    .highlight .kr { color: #228899; font-weight: bold } /* Keyword.Reserved */
    .highlight .kt { color: #6666ff; font-weight: bold } /* Keyword.Type */
    .highlight .m { color: #6600EE; font-weight: bold } /* Literal.Number */
    .highlight .s { background-color: #e0e0ff } /* Literal.String */
    .highlight .na { color: #000077 } /* Name.Attribute */
    .highlight .nb { color: #007722 } /* Name.Builtin */
    .highlight .nc { color: #ee99ee; font-weight: bold } /* Name.Class */
    .highlight .no { color: #55eedd; font-weight: bold } /* Name.Constant */
    .highlight .nd { color: #555555; font-weight: bold } /* Name.Decorator */
    .highlight .ni { color: #880000 } /* Name.Entity */
    .highlight .ne { color: #FF0000; font-weight: bold } /* Name.Exception */
    .highlight .nf { color: #55eedd; font-weight: bold } /* Name.Function */
    .highlight .nl { color: #997700; font-weight: bold } /* Name.Label */
    .highlight .nn { color: #0e84b5; font-weight: bold } /* Name.Namespace */
    .highlight .nt { color: #007700 } /* Name.Tag */
    .highlight .nv { color: #003366 } /* Name.Variable */
    .highlight .ow { color: #000000; font-weight: bold } /* Operator.Word */
    .highlight .w { color: #bbbbbb } /* Text.Whitespace */
    .highlight .mb { color: #6600EE; font-weight: bold } /* Literal.Number.Bin */
    .highlight .mf { color: #6600EE; font-weight: bold } /* Literal.Number.Float */
    .highlight .mh { color: #005588; font-weight: bold } /* Literal.Number.Hex */
    .highlight .mi { color: #6666ff; font-weight: bold } /* Literal.Number.Integer */
    .highlight .mo { color: #4400EE; font-weight: bold } /* Literal.Number.Oct */
    .highlight .sa { background-color: #e0e0ff } /* Literal.String.Affix */
    .highlight .sb { background-color: #e0e0ff } /* Literal.String.Backtick */
    .highlight .sc { color: #8888FF } /* Literal.String.Char */
    .highlight .dl { background-color: #e0e0ff } /* Literal.String.Delimiter */
    .highlight .sd { color: #DD4422 } /* Literal.String.Doc */
    .highlight .s2 { background-color: #e0e0ff } /* Literal.String.Double */
    .highlight .se { color: #666666; font-weight: bold; background-color: #e0e0ff } /* Literal.String.Escape */
    .highlight .sh { background-color: #e0e0ff } /* Literal.String.Heredoc */
    .highlight .si { background-color: #eeeeee } /* Literal.String.Interpol */
    .highlight .sx { color: #ff8888; background-color: #e0e0ff } /* Literal.String.Other */
    .highlight .sr { color: #000000; background-color: #e0e0ff } /* Literal.String.Regex */
    .highlight .s1 { background-color: #e0e0ff } /* Literal.String.Single */
    .highlight .ss { color: #ffcc88 } /* Literal.String.Symbol */
    .highlight .bp { color: #007722 } /* Name.Builtin.Pseudo */
    .highlight .fm { color: #55eedd; font-weight: bold } /* Name.Function.Magic */
    .highlight .vc { color: #ccccff } /* Name.Variable.Class */
    .highlight .vg { color: #ff8844 } /* Name.Variable.Global */
    .highlight .vi { color: #aaaaff } /* Name.Variable.Instance */
    .highlight .vm { color: #003366 } /* Name.Variable.Magic */
    .highlight .il { color: #6666ff; font-weight: bold } /* Literal.Number.Integer.Long */

    /*Dark Theme*/

    body.dark td.linenos .normal { color: #37474F; background-color: #263238; padding-left: 5px; padding-right: 5px; }
    body.dark span.linenos { color: #37474F; background-color: #263238; padding-left: 5px; padding-right: 5px; }
    body.dark td.linenos .special { color: #607A86; background-color: #263238; padding-left: 5px; padding-right: 5px; }
    body.dark span.linenos.special { color: #607A86; background-color: #263238; padding-left: 5px; padding-right: 5px; }
    body.dark .highlight .hll { background-color: #2C3B41 }
    body.dark .highlight { background: #263238; color: #EEFFFF }
    body.dark .highlight .c { color: #546E7A; font-style: italic } /* Comment */
    body.dark .highlight .err { color: #FF5370 } /* Error */
    body.dark .highlight .esc { color: #89DDFF } /* Escape */
    body.dark .highlight .g { color: #EEFFFF } /* Generic */
    body.dark .highlight .k { color: #BB80B3 } /* Keyword */
    body.dark .highlight .l { color: #C3E88D } /* Literal */
    body.dark .highlight .n { color: #EEFFFF } /* Name */
    body.dark .highlight .o { color: #89DDFF } /* Operator */
    body.dark .highlight .p { color: #89DDFF } /* Punctuation */
    body.dark .highlight .ch { color: #546E7A; font-style: italic } /* Comment.Hashbang */
    body.dark .highlight .cm { color: #546E7A; font-style: italic } /* Comment.Multiline */
    body.dark .highlight .cp { color: #546E7A; font-style: italic } /* Comment.Preproc */
    body.dark .highlight .cpf { color: #546E7A; font-style: italic } /* Comment.PreprocFile */
    body.dark .highlight .c1 { color: #546E7A; font-style: italic } /* Comment.Single */
    body.dark .highlight .cs { color: #546E7A; font-style: italic } /* Comment.Special */
    body.dark .highlight .gd { color: #FF5370 } /* Generic.Deleted */
    body.dark .highlight .ge { color: #89DDFF } /* Generic.Emph */
    body.dark .highlight .gr { color: #FF5370 } /* Generic.Error */
    body.dark .highlight .gh { color: #C3E88D } /* Generic.Heading */
    body.dark .highlight .gi { color: #C3E88D } /* Generic.Inserted */
    body.dark .highlight .go { color: #546E7A } /* Generic.Output */
    body.dark .highlight .gp { color: #FFCB6B } /* Generic.Prompt */
    body.dark .highlight .gs { color: #FF5370 } /* Generic.Strong */
    body.dark .highlight .gu { color: #89DDFF } /* Generic.Subheading */
    body.dark .highlight .gt { color: #FF5370 } /* Generic.Traceback */
    body.dark .highlight .kc { color: #89DDFF } /* Keyword.Constant */
    body.dark .highlight .kd { color: #BB80B3 } /* Keyword.Declaration */
    body.dark .highlight .kn { color: #89DDFF; font-style: italic } /* Keyword.Namespace */
    body.dark .highlight .kp { color: #89DDFF } /* Keyword.Pseudo */
    body.dark .highlight .kr { color: #BB80B3 } /* Keyword.Reserved */
    body.dark .highlight .kt { color: #BB80B3 } /* Keyword.Type */
    body.dark .highlight .ld { color: #C3E88D } /* Literal.Date */
    body.dark .highlight .m { color: #F78C6C } /* Literal.Number */
    body.dark .highlight .s { color: #C3E88D } /* Literal.String */
    body.dark .highlight .na { color: #BB80B3 } /* Name.Attribute */
    body.dark .highlight .nb { color: #82AAFF } /* Name.Builtin */
    body.dark .highlight .nc { color: #FFCB6B } /* Name.Class */
    body.dark .highlight .no { color: #EEFFFF } /* Name.Constant */
    body.dark .highlight .nd { color: #82AAFF } /* Name.Decorator */
    body.dark .highlight .ni { color: #89DDFF } /* Name.Entity */
    body.dark .highlight .ne { color: #FFCB6B } /* Name.Exception */
    body.dark .highlight .nf { color: #82AAFF } /* Name.Function */
    body.dark .highlight .nl { color: #82AAFF } /* Name.Label */
    body.dark .highlight .nn { color: #FFCB6B } /* Name.Namespace */
    body.dark .highlight .nx { color: #EEFFFF } /* Name.Other */
    body.dark .highlight .py { color: #FFCB6B } /* Name.Property */
    body.dark .highlight .nt { color: #FF5370 } /* Name.Tag */
    body.dark .highlight .nv { color: #89DDFF } /* Name.Variable */
    body.dark .highlight .ow { color: #89DDFF; font-style: italic } /* Operator.Word */
    body.dark .highlight .w { color: #EEFFFF } /* Text.Whitespace */
    body.dark .highlight .mb { color: #F78C6C } /* Literal.Number.Bin */
    body.dark .highlight .mf { color: #F78C6C } /* Literal.Number.Float */
    body.dark .highlight .mh { color: #F78C6C } /* Literal.Number.Hex */
    body.dark .highlight .mi { color: #F78C6C } /* Literal.Number.Integer */
    body.dark .highlight .mo { color: #F78C6C } /* Literal.Number.Oct */
    body.dark .highlight .sa { color: #BB80B3 } /* Literal.String.Affix */
    body.dark .highlight .sb { color: #C3E88D } /* Literal.String.Backtick */
    body.dark .highlight .sc { color: #C3E88D } /* Literal.String.Char */
    body.dark .highlight .dl { color: #EEFFFF } /* Literal.String.Delimiter */
    body.dark .highlight .sd { color: #546E7A; font-style: italic } /* Literal.String.Doc */
    body.dark .highlight .s2 { color: #C3E88D } /* Literal.String.Double */
    body.dark .highlight .se { color: #EEFFFF } /* Literal.String.Escape */
    body.dark .highlight .sh { color: #C3E88D } /* Literal.String.Heredoc */
    body.dark .highlight .si { color: #89DDFF } /* Literal.String.Interpol */
    body.dark .highlight .sx { color: #C3E88D } /* Literal.String.Other */
    body.dark .highlight .sr { color: #89DDFF } /* Literal.String.Regex */
    body.dark .highlight .s1 { color: #C3E88D } /* Literal.String.Single */
    body.dark .highlight .ss { color: #89DDFF } /* Literal.String.Symbol */
    body.dark .highlight .bp { color: #89DDFF } /* Name.Builtin.Pseudo */
    body.dark .highlight .fm { color: #82AAFF } /* Name.Function.Magic */
    body.dark .highlight .vc { color: #89DDFF } /* Name.Variable.Class */
    body.dark .highlight .vg { color: #89DDFF } /* Name.Variable.Global */
    body.dark .highlight .vi { color: #89DDFF } /* Name.Variable.Instance */
    body.dark .highlight .vm { color: #82AAFF } /* Name.Variable.Magic */
    body.dark .highlight .il { color: #F78C6C } /* Literal.Number.Integer.Long */

